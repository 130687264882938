@import "@ag-grid-community/styles/ag-grid.css";
@import "@ag-grid-community/styles/ag-theme-alpine.css";

.ag-theme-alpine-dark {
    --ag-background-color: #ecf8f1;
    --ag-header-background-color: #5bc17f; 
    --ag-odd-row-background-color: #f7fcf9; 
    --ag-borders: none;
    border-radius: 16px;
    border: solid #5BC17F;
    border-width: 2px;
    font-family: 'Poppins';
    --ag-data-color: #333;
    font-weight: 500;
    /* --ag-row-hover-color: ; */
    --ag-row-border-color: #5bc17f

}

body {
    margin: 0;
}

nav {
    margin: 0;
}

#map {
    height: 180px;
}

.leaflet-container {
    width: 100%;
    height: 100%;
}
  
 

@tailwind base
@tailwind components;
@tailwind utilities;

    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-BoldItalic.ttf') format('truetype');
        font-weight: 700;
        font-style: italic;
    }

    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-Black.ttf') format('truetype');
        font-weight: 900;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-BlackItalic.ttf') format('truetype');
        font-weight: 900;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-MediumItalic.ttf') format('truetype');
        font-weight: 500;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-Italic.ttf') format('truetype');
        font-weight: 400;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-Thin.ttf') format('truetype');
        font-weight: 100;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-ThinItalic.ttf') format('truetype');
        font-weight: 100;
        font-style: italic;
    }

    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-ExtraLight.ttf') format('truetype');
        font-weight: 200;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-ExtraLightItalic.ttf') format('truetype');
        font-weight: 200;
        font-style: italic;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    
    @font-face {
        font-family: 'Poppins';
        src: url('./fonts/Poppins-LightItalic.ttf') format('truetype');
        font-weight: 300;
        font-style: italic;
    }

    /* Inter Fonts */
    @font-face {
        font-family: 'Inter';
        src: url('./fonts/Inter-Thin.ttf') format('truetype');
        font-weight: 100;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter';
        src: url('./fonts/Inter-ExtraLight.ttf') format('truetype');
        font-weight: 200;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter';
        src: url('./fonts/Inter-Light.ttf') format('truetype');
        font-weight: 300;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter';
        src: url('./fonts/Inter-Regular.ttf') format('truetype');
        font-weight: 400;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter';
        src: url('./fonts/Inter-Medium.ttf') format('truetype');
        font-weight: 500;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter';
        src: url('./fonts/Inter-SemiBold.ttf') format('truetype');
        font-weight: 600;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter';
        src: url('./fonts/Inter-Bold.ttf') format('truetype');
        font-weight: 700;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter';
        src: url('./fonts/Inter-ExtraBold.ttf') format('truetype');
        font-weight: 800;
        font-style: normal;
    }
    @font-face {
        font-family: 'Inter';
        src: url('./fonts/Inter-Black.ttf') format('truetype');
        font-weight: 900;
        font-style: normal;
    }
    
    
    .pricing-title {
        font-feature-settings: 'dlig' 0, "liga" 0
    }